











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Potassium hydroxide', value: 'potassiumHydroxide'},
        {text: 'Sodium chloride', value: 'sodiumChloride'},
        {text: 'Sodium carbonate', value: 'sodiumCarbonate'},
        {text: 'Magnesium bromide', value: 'magnesiumBromide'},
        {text: 'Anhydrous sodium sulfate', value: 'sodiumSulfate'},
      ],
      optionsFr: [
        {text: 'Hydroxyde de potassium', value: 'potassiumHydroxideFr'},
        {text: 'Chlorure de sodium', value: 'sodiumChlorideFr'},
        {text: 'Sulfate de sodium anhydre', value: 'sodiumSulfateFr'},
        {text: 'Carbonate de sodium', value: 'sodiumCarbonateFr'},
        {text: 'Bromure de magnésium', value: 'magnesiumBromideFr'},
      ],
    };
  },
};
